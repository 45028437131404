module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-GSWW0YMYNW","cookieName":"ga-cookie-consent","anonymize":true,"allowAdFeatures":true},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ilSalice EXTRA","short_name":"EXTRA","description":"Art and culture blog","icon":"src/images/icon.png","lang":"en","display":"standalone","start_url":"https://extra.ilsalice.org/","background_color":"#000000","theme_color":"#e4cc76","localize":[{"start_url":"https://extra.ilsalice.org/it/","lang":"it","name":"ilSalice EXTRA","short_name":"EXTRA","description":"Blog di arte e cultura"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e060c351fc43c9d06196d2bc3d246b5c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
